import styled from '@emotion/styled';
import { Box, Grid } from '@material-ui/core';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import slugify from 'slugify';

import { useBookmarks } from '@/context/bookmarks/bookmarks.provider';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { useMyPageData } from '@/hooks/useMyPageData';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { MoviesCarusel } from '@/payload-types';
import { MediaComponent } from '@/payload/components/Media';
import { mqMax } from '@/styles/breakpoints';

import { Button } from './Button/Button';
import { IconButton } from './Button/IconButton';
import LikeMovieButton from './LikeMovieButton/LikeMovieButton';
import { Typography } from './Typography';
import ArrowIconBlackSmall from './icons/new/ArrowIconBlackSmall';
import PlayButtonBlack from './icons/new/PlayButtonBlack';

const ContentWrapper = styled(Grid)`
  position: absolute;
  bottom: 0;
  left: 10rem;

  height: auto;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
  max-width: 600px;

  ${mqMax[3]} {
    max-width: 500px;
  }

  ${mqMax[5]} {
    left: 10vw;
    max-width: 450px;
  }

  ${mqMax[4]} {
    position: static;

    height: auto;
    padding: 2rem;
  }
`;

const CaruselWrapper = styled.div`
  position: relative;
  height: 100%;
  max-height: 100vh;
  min-height: 600px;
  width: 100%;
  ${mqMax[4]} {
    position: static;
  }
`;

const MediaWrapper = styled.div`
  width: 100%;
  height: 120rem;
  object-fit: cover;

  ${mqMax[2]} {
    height: 100rem;
  }
  ${mqMax[3]} {
    height: 50rem;
  }
`;

interface TextProps {
  color?: string;
}

const Tag = styled.div<TextProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color, theme }) => color ?? theme.palette.primary.main};
`;

const Dot = styled.div`
  margin: 0 12px;
`;
type Movie = NonNullable<MoviesCarusel['movie']>[0];
interface MovieCaruselBlockItemProps {
  movie: Movie;
  authUser?: IAuthUser;
  clientIp?: string;
}

const BackgroundMedia = styled.div<{ source: string }>`
  background: url("${(props) => props.source}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  max-height: 900px;
  min-height: 640px;

  ${mqMax[4]} {
    height: 100vw;
    max-height: initial;
    min-height: initial;
  }
`;

const MovieCaruselBlockItem: FC<MovieCaruselBlockItemProps> = ({ movie, authUser, clientIp }) => {
  const [isInMyList, setIsInMyList] = useState(false);

  const { addToFavorites, removeFromFavorites, bookmarksCollection } = useBookmarks();

  const { isActiveSubscriber } = useSubscriptionData();

  const mobileDevice = useMemo(() => isIOS || isAndroid, []);

  useEffect(() => {
    if (bookmarksCollection.length > 0) {
      const isInBookmarksCollection = bookmarksCollection.some(
        (bookmark) => bookmark.node.magineId === movie.magineMovieId
      );
      setIsInMyList(isInBookmarksCollection);
    }
  }, [movie.magineMovieId, bookmarksCollection]);

  const handleAddToFavorites = useCallback(async () => {
    if (authUser?.token && movie.magineMovieId) {
      await addToFavorites(movie.magineMovieId);
      setIsInMyList(true);
    }
  }, [addToFavorites, authUser?.token, movie.magineMovieId]);

  const handleRemoveFromFavorites = useCallback(async () => {
    if (authUser?.token && movie.magineMovieId) {
      await removeFromFavorites(movie.magineMovieId);
      setIsInMyList(false);
    }
  }, [removeFromFavorites, authUser?.token, movie?.magineMovieId]);
  return (
    <>
      {movie && (
        <CaruselWrapper key={movie?.id}>
          <BackgroundMedia source={(movie.backgroundMedia as any).url} />
          {/* <MediaWrapper>
            <MediaComponent source={movie.backgroundMedia} />
          </MediaWrapper> */}
          <ContentWrapper item xs={6} md={5}>
            <Typography variant="headlineSmall" customColor={movie.title.color as string | undefined} fontWeight='500'>
              {movie.title.titleText}{' '}
            </Typography>
            <Box mt={4} mb={4}>
              <Typography variant="bodyMedium" customColor={movie.description.color as string | undefined}>
                {movie.description.descriptionText}
              </Typography>
            </Box>
            <Box flexWrap="wrap" display="flex" justifyContent="flex-start" flexDirection="row">
              {movie.tags?.map((tag, index) => (
                <Tag key={tag.id} color={tag.color as string | undefined}>
                  {index > 0 && <Dot> · </Dot>}
                  <Typography variant="titleSmall" customColor={tag.color as string | undefined}>
                    {tag.tagText.toUpperCase()}
                  </Typography>
                </Tag>
              ))}
            </Box>
            {isActiveSubscriber && authUser?.token ? (
              <Box marginTop={6} flexWrap="wrap" display="flex" justifyContent="flex-start" flexDirection="row">
                {movie.movieSlug && movie.shouldShowPlayButton && !mobileDevice && (
                  <Box marginRight={4}>
                    <Button
                      size="small"
                      color="white"
                      href={`/film/${slugify(movie.movieSlug, { lower: true, strict: true })}?autoPlay=true`}
                      startIcon={<PlayButtonBlack />}
                      openInNewTab={false}
                    >
                      SPELA FILM
                    </Button>
                  </Box>
                )}
                {movie.movieSlug && movie.shouldShowReadMoreButton && (
                  <Box marginRight={4}>
                    <IconButton
                      size="small"
                      color="white"
                      href={`/film/${slugify(movie.movieSlug, { lower: true, strict: true })}`}
                    >
                      <ArrowIconBlackSmall />
                    </IconButton>
                  </Box>
                )}
                {movie.shouldShowBookmarkButton && (
                  <Box>
                    <LikeMovieButton
                      inMyList={isInMyList}
                      handleRemoveFromFavorites={handleRemoveFromFavorites}
                      handleAddToFavorites={handleAddToFavorites}
                      size="small"
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Box mt={6} mb={4}>
                <Button size="large" color="red" href="/offers" openInNewTab={false}>
                  PROVA NU
                </Button>
              </Box>
            )}
          </ContentWrapper>
        </CaruselWrapper>
      )}
    </>
  );
};

export default MovieCaruselBlockItem;
